<template>
<div class="out">
  <Header1></Header1>
  <Header></Header>
  <!-- banner图 -->
  <Banner></Banner>
  <!-- content -->
  <div class="content">
    <el-card class="box-card" v-for="(blog,index) in blogs" :key="index" 
      v-bind:style="{backgroundColor:getColor() , backgroundImage: 'url(' + coverImgUrl2() + ') ' }" >
      <div class="cover"> <!--遮罩-->
        <router-link :to="{name:'BlogDetail' , params:{blogId:blog.id}}"> 
          <div  class="text item" >
            <div class="wrapper">
              <div class="title">{{blog.title}}</div>    
              <div class="description">{{blog.description}}</div>
            </div>
          </div>
        </router-link>
      </div>
    </el-card>
    <!-- 分页 -->
    <el-pagination class="page"
      background
      layout="prev, pager, next"
      :current-page="currentPage"
      :page-size="pageSize"
      :total="total"
      :pager-count="pagerCount"
      @current-change=page>
    </el-pagination>
  </div>
  <!-- bottom区域 -->
  <Version></Version>
  <el-backtop class="up"> <i class="el-icon-top"></i></el-backtop>
</div>
</template>


<script>
import Header1 from "../components/Header1.vue"  ;
import Header from "../components/Header.vue"  ;
import BlogItem from "../components/BlogItem.vue"  ;
import Banner from "../components/Banner.vue"  ;
import Version from "../components/Version.vue"  ;
// import * as sysTool from '../assets/systools.js' ; 
export default {
  name : 'Index' , 
  components:{Header1,Banner,Header,BlogItem,Version} , 
  data(){
    return {
      blogs: {} , 
      currentPage:1 , 
      total:0 , 
      pageSize:5 , 
      pagerCount:5,
      collorArr:["pink" , "#AFEEEE" , "#abf" , "#9370DB" , "#F4A460" , "#90EE90" , "#6495ED" , "	#A9A9A9" , "#DDA0DD" , "	#F0E68C"] ,
      bgCol:"#abf" , 
      imgUrls2:[] ,
      visitorInfo:{
        ip: '1.1.1.1',
        area: '北京市',
        browser: 'chrome',
        os: 'windows' , 
        // osVersion: 'windows 10' ,
        // location:'' , 
        agentInfo:'' , 
        isp:'' , 
        cost:''
      }
    } 
  } , 
  methods:{
    // 获取列表数据
    page(currentPage){
      // const _this = this ; 
      this.$axios.get("/blogs?currentPage=" + currentPage + "&pageSize=" + this.pageSize ).then(res => {
        this.blogs = res.data.data.records ; 
        this.currentPage = res.data.data.current ; 
        this.total = res.data.data.total ; 
        this.pageSize = res.data.data.size ; 
        // console.log(_this.blogs);
      }) ; 
    } ,
    // 获取我们设置的随机颜色作为展示博客的card的背景
    getColor(){
      let num = Math.floor(Math.random()*10);  
      return this.collorArr[num] ; 
    }  , 
    // 获取博客item背景图
    coverImgUrl2(){
      let num = Math.floor(Math.random()*this.imgUrls2.length);  
      return this.imgUrls2[num] ; 
    } ,
    // 获取访客信息 并保存
    async getVisitorInfo(){
      const _this = this ; 
      await this.$axios.get("/getIpInfo").then(res => {
        // console.log(res) ; 
        let area = "" ; 
        if(res.data.data.country!== null){
          area += res.data.data.country ; 
        }
        if(res.data.data.region!==null){
          area += res.data.data.region ; 
        }
        if (res.data.data.province !== null) {
          area += res.data.data.province ; 
        }
        if (res.data.data.city !== null) {
          area += res.data.data.city ; 
        }
        sessionStorage.setItem("area" , area) ; 
        // console.log(area);
        this.visitorInfo.ip = res.data.data.ip ; 
        this.visitorInfo.area = area ; 
        this.visitorInfo.os = res.data.data.os ; 
        this.visitorInfo.browser = res.data.data.browser ;
        this.visitorInfo.isp = res.data.data.isp ;  
        this.visitorInfo.agentInfo = res.data.data.agentInfo ;
        this.visitorInfo.cost = res.data.data.cost ;  
      }) ; 

      // console.log(this.visitorInfo);
      await this.$axios.post('/addVisitorData' , this.visitorInfo , {

      })
    } , 
    // 获取blog的title和描述的背景图片
    coverImgUrlBlog(){
      const _this = this ; 
      _this.$axios.get("/getBgBlog").then(res => {
        // console.log(res) ; 
        this.imgUrls2 = res.data.data ; 
      }) ; 
    }
  } , 
  created(){
    this.page(this.currentPage) ; 
    this.getVisitorInfo() ; 
    this.coverImgUrlBlog() ; 
  }
}
</script>


<style scoped>
@media all and (min-width:850px) {
  .content{
    width: 45vw;
    text-align: center;
    margin: 0 auto;
    margin-top: 5rem;
    margin-bottom: 100px;
    /* position: absolute;
    top: 140px;
    left: 0;
    bottom: 0;
    right: 0; */
  }
  .content .box-card{
    position: relative;
    width: 100%;
    height: 27vh;
    box-sizing: border-box;
    background-color: pink;
    border-radius: 10px;
    margin: 60px 0 50px 0;
    background-position: center;
    background-repeat:no-repeat;
    background-size: 100%;
    /* filter:Alpha(Opacity=100);opacity:0.9; */
  }
  .content .box-card .cover{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba( 0, 0, 0, 0.2);
  }
  .content .box-card:hover{
    cursor: pointer;
    /* width: 980px;
    height: 210px; */
    transform:  scale(1.03) ; 
    box-shadow: 1px 5px 20px rgba( 0, 0, 0, 0.3);
  }
  .content .box-card .wrapper{
    width: 35vw;
    margin:  0 auto;
    margin-top: 8vh;
    text-align: center;
    color: black;
  }
  .content .box-card .title{
    font-size: 24px;
    font-weight: 500;
    color: white;
  }
  .content .box-card .description{
    margin-top: 10px;
    font-size: 14px;
    color: white;
  }
  
}


@media all and (max-width:850px) {
  .content{
    width: 96vw;
    text-align: center;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 100px;
    /* position: absolute;
    top: 720px;
    left: 0;
    bottom: 0;
    right: 0; */
  }
  .content .box-card{
    position: relative;
    width: 100%;
    height: 19rem;
    box-sizing: border-box;
    background-color: pink;
    border-radius: 10px;
    margin: 40px 0 40px 0;
    background-position: center;
    background-repeat:no-repeat;
    background-size: 100%;
    /* filter:Alpha(Opacity=100);opacity:0.9; */
  }
  .content .box-card .cover{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba( 0, 0, 0, 0.2);
  }
  .content .box-card:hover{
    cursor: pointer;
    /* width: 980px;
    height: 210px; */
    /* transform:  scale(1.03) ;  */
    box-shadow: 1px 5px 20px rgba( 0, 0, 0, 0.3);
  }
  .content .box-card .wrapper{
    width: 80vw;
    margin:  0 auto;
    margin-top: 65px;
    text-align: center;
    color: black;
  }
  .content .box-card .title{
    font-size: 1.8rem;
    font-weight: 500;
    color: white;
  }
  .content .box-card .description{
    margin-top: 1rem;
    font-size: 1.2rem;
    color: white;
  }

}

/* .page{
  width: 20px;
  height: 10px;
  margin-left: 700px;
  
} */
.up{
  background-color: rgba(255, 255, 255, 0.7);
  
}
</style>